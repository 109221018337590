import type { FC } from 'react';
import { useState } from 'react';
interface userProps {
  user: any;
}

const User: FC<userProps> = ({ user }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);
  const [hideItem, setHideItem] = useState(false);
  const accessLevels = JSON.parse(user.access_levels);
  const deleteUser = async (id: number) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this user?'
    );
    if (!confirmDelete) {
      return;
    }
    setLoadingDelete(true);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/delete-user.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          id: id,
          email: user.email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'success') {
            setHideItem(true);
          } else if (data.error) {
            alert(data.error);
            setLoadingDelete(false);
          } else {
            alert('Failed, try again');
            setLoadingDelete(false);
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          alert(error);
          setLoadingDelete(false);
        }));
  };
  const userRole = (role: number) => {
    switch (role) {
      case 1:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-green-200"
          ></span>
        );

      case 3:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-blue-200"
          ></span>
        );
      case 4:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-purple-200"
          ></span>
        );
      case 5:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-amber-200"
          ></span>
        );
      case 6:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-amber-400"
          ></span>
        );
      case 7:
        return (
          <span
            key={`${user.id}-role-${role}`}
            className="w-3 h-3 text-[9px] block uppercase rounded-full bg-amber-600"
          ></span>
        );
      default:
        return '';
    }
  };
  const sendActivationMail = async (id: number) => {
    setLoadingMail(true);

    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/send-activation-mail.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + storedJWT,
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'success') {
          alert('Activation mail sent successfully');
        } else if (data.error) {
          alert(data.error);
        } else {
          alert('Something went wrong, try again.');
        }
        setLoadingMail(false);
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);
        alert(error);
        setLoadingMail(false);
      });
  };
  return (
    !hideItem && (
      <div className="grid items-center grid-cols-7 gap-0 border-b border-gray-200 hover:bg-gray-100  min-w-[800px]">
        <div>
          <p className="px-3 py-2 overflow-hidden text-sm overflow-ellipsis">
            {user.lastname}
          </p>
        </div>
        <div>
          <p className="px-3 py-2 overflow-hidden text-sm overflow-ellipsis">
            {user.firstname}
          </p>
        </div>
        <div>
          <p className="flex flex-wrap px-3 gap-0.5 py-2 text-sm">
            {accessLevels.map((role: number) => userRole(role))}
          </p>
        </div>
        <div>
          <p
            title={user.email}
            className="px-3 py-2 overflow-hidden text-sm overflow-ellipsis"
          >
            {user.email}
          </p>
        </div>
        <div>
          <p className="px-3 py-2 overflow-hidden text-sm overflow-ellipsis">
            {user.phone}
          </p>
        </div>
        <div>
          {user.active == 1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-3 stroke-green-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 ml-3 stroke-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          )}
        </div>

        <div className="flex justify-end">
          <div className="grid content-center mr-5 xl:mr-10">
            {loadingMail ? (
              <svg
                className={` w-6 h-6 animate-spin`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                onClick={() => sendActivationMail(user.ID)}
                className="w-6 h-6 transition cursor-pointer stroke-blue-500 hover:stroke-blue-200"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            )}
          </div>

          {loadingDelete ? (
            <svg
              className={` w-6 h-6 animate-spin mr-5`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#ffffff"
              className={` 
                  p-1 transition-colors    mr-5  bg-opacity-50 rounded-md cursor-pointer w-7 h-7  bg-slate-700 hover:bg-red-700 group-hover:inline-block`}
              onClick={() => deleteUser(user.ID)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          )}
        </div>
      </div>
    )
  );
};
export default User;
