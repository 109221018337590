import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface FileUploadState {
  selectedFile: File | null;
}
interface FileUploadProps {
  folderPath: string;
  updateFolder: () => void;
}
const FileUpload: React.FC<FileUploadProps> = ({
  folderPath,
  updateFolder,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileAccessLevels, setFileAccessLevels] = useState<Array<number>>([]);
  const [error, setError] = useState(``);
  const [success, setSuccess] = useState(``);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<any>(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const refresh = () => {
    setSelectedFiles([]);
    setFileAccessLevels([]);
    setUploadProgress(0);
    // empty the input file
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    fileInput.value = '';
    updateFolder();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };
  const handleFileUpload = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    const storedJWT = localStorage.getItem('jwt');
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('files[]', file); // Use "files[]" to handle multiple files
      });
      //convert array to string
      formData.append('access_level', fileAccessLevels.join(','));
      formData.append('folder_path', folderPath);

      try {
        await axios
          .post(process.env.REACT_APP_API_URL + '/upload-file.php', formData, {
            headers: {
              Authorization: 'Bearer ' + storedJWT,
            },
            onUploadProgress: (p) => {
              //set upload progress in %
              p.total &&
                setUploadProgress(Math.round((p.loaded / p.total) * 100));
            },
          })
          .then((response) => {
            if (response.data === 'Error decoding token: Expired token') {
              logout();
            }
            const jsonData = response.data;

            setLoading(false);
            if (jsonData.error.length > 0) {
              setError(jsonData.error.join('<br/>'));
            } else if (jsonData.success.length > 0) {
              setSuccess(
                'Files uploaded successfully:<br/>' +
                  jsonData.success.join('<br/>')
              ); // Join success messages with newlines

              refresh();
            } else {
              setError('Something went wrong, please try again.');
            }
          });
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setError('Error uploading file');
        setLoading(false);
      }
    } else {
      setError('Please select a file to upload.');
      setLoading(false);
    }
  };
  const toggleAccessLevel = (index: number) => {
    if (fileAccessLevels.includes(index)) {
      //remove from array
      setFileAccessLevels(fileAccessLevels.filter((item) => item !== index));
    } else {
      //add to array
      setFileAccessLevels([...fileAccessLevels, index]);
    }
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  const getRoles = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-roles.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRoles(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className="inline-block pt-6 pr-6 mt-10 bg-white md:mt-5 md:rounded-lg md:shadow-md md:p-6">
      <h1 className="mb-4 text-xl font-bold text-gray-800">Upload Files</h1>
      <input
        className="block w-full text-sm text-gray-700 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-[#e9e9e5] file:text-default hover:file:bg-indigo-100"
        type="file"
        name="files[]"
        multiple
        onChange={handleFileChange}
      />
      {loading && (
        <div className="relative h-2 mt-3 overflow-hidden rounded-full">
          <div className="absolute inset-0 bg-slate-200"></div>
          <div
            style={{ width: `${uploadProgress}%` }}
            className="absolute inset-0 transition-all duration-500 ease-out bg-indigo-600"
          ></div>
        </div>
      )}
      <div className="mt-4 mb-6">
        <label
          htmlFor="access-level"
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Access Level:
        </label>
        <div className="relative flex flex-wrap inline-block w-auto">
          {roles.map((level: any) => (
            <a
              onClick={() => toggleAccessLevel(level.access_level)}
              key={level.access_level}
              className={`${
                fileAccessLevels.includes(level.access_level)
                  ? 'bg-green-600 text-white'
                  : 'bg-red-100 hover:bg-green-100 text-default'
              } mb-2 mr-3 cursor-pointer group py-1 px-3 rounded-md text-sm`}
            >
              {level.label}
            </a>
          ))}
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-start">
          <svg
            className="inline-block w-5 h-5 mr-3 text-accent animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="text-sm font-medium text-gray-500">
            Uploading...
          </span>
        </div>
      ) : (
        <button
          className="inline-flex justify-center w-auto px-4 py-2 mt-3 text-sm font-medium text-white bg-default border border-transparent rounded-md shadow-sm hover:bg-[#d3d3cd] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#e9e9e5]"
          onClick={handleFileUpload}
        >
          Upload
        </button>
      )}

      <p
        className="mt-2 text-sm font-medium text-green-600"
        dangerouslySetInnerHTML={{ __html: success }}
      />
      <p
        className="mt-2 text-sm font-medium text-red-600"
        dangerouslySetInnerHTML={{ __html: error }}
      />
    </div>
  );
};

export default FileUpload;
