import { FC, useState } from 'react';
import React from 'react';
import Navigation from '../components/navigation';
import ContactInfo from '../components/contactinfo';
//make an infterface for children props
interface LayoutProps {
  children: React.ReactNode;
}
const Layout: FC<LayoutProps> = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  return (
    <main className="">
      <div className="flex justify-start">
        <div className="lg:max-w-[300px] lg:w-[300px] bg-[#FAFBFA]">
          <Navigation />
        </div>
        <div className="w-full max-w-5xl overflow-x-hidden">
          <div className="flex justify-between px-5 py-3 border-b border-slate-100 md:pl-5 lg:pl-10 xl:pl-20 md:py-7 md:pr-7">
            <div></div>
            <div>
              <p
                className="text-xs font-medium leading-6 md:text-sm "
                aria-hidden="true"
              >
                {user?.firstname} {user?.lastname}
              </p>
            </div>
          </div>
          {children}
          <div className="py-12 lg:hidden">
            <ContactInfo />
          </div>
        </div>
      </div>
    </main>
  );
};
export default Layout;
