import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FileUpload from '../components/fileupload';
import CreateFolder from '../components/createfolder';
import FileBrowser from '../components/filebrowser';
import SearchFiles from '../components/searchfiles';
//files interface
interface filesProps {}
const Files: FC<filesProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const [folderPath, setFolderPath] = useState<string>('/');
  const [counter, setCounter] = useState(0);
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };

  const updateFolder = () => {
    setCounter((counter) => counter + 1);
  };

  const updateFolderPath = (path: string) => {
    setFolderPath(path);
  };

  useEffect(() => {
    checkStatus();
  }, []);

  let roles = [];

  if (user) {
    roles = JSON.parse(user.roles || '[]');
  }

  return (
    <>
      <div className="relative z-10 p-3 fade-in md:pt-10 md:p-5 lg:pl-10 xl:pl-16">
        <div className="flex rounded-xl bg-default">
          <div className="w-full md:my-5 lg:w-[50%] xl:w-[40%] p-5 md:p-10 lg:p-10 xl:py-12 xl:px-16">
            <h2 className="text-base text-white md:py-2 md:text-xl md:mb-3">
              Welcome {user.firstname},<br />
              to the Gommaire library
            </h2>
            <div className="my-3 max-w-[220px] ">
              <SearchFiles
                updateFolderPath={updateFolderPath}
                path={folderPath}
              />
            </div>
          </div>
          <div className="relative hidden overflow-hidden rounded-xl lg:block lg:w-[50%] xl:w-[60%]">
            <img
              src="/gommaire-cover.jpg"
              alt=""
              className="absolute object-cover w-full h-full"
            />
          </div>
        </div>
      </div>

      <FileBrowser
        folderPath={folderPath}
        changeFolderPath={updateFolderPath}
        refreshCounter={counter}
      />
      <div className="grid max-w-3xl gap-10 p-3 mb-10 lg:grid-cols-2 md:mb-0 md:px-5 lg:pl-10 xl:pl-16">
        {(roles.includes(0) || roles.includes(1)) && (
          <FileUpload updateFolder={updateFolder} folderPath={folderPath} />
        )}
        {(roles.includes(0) || roles.includes(1)) && (
          <CreateFolder
            updateFolderPath={updateFolderPath}
            folderPath={folderPath}
          />
        )}
      </div>
    </>
  );
};
export default Files;
