import type { FC } from 'react';
import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface createFolderProps {
  folderPath: string;
  updateFolderPath: (path: string) => void;
}

const CreateFolder: FC<createFolderProps> = ({
  folderPath,
  updateFolderPath,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [roles, setRoles] = useState<any>([]);
  const [folderError, setFolderError] = useState('');
  const [folderSuccess, setFolderSuccess] = useState('');
  const [folderAccessLevels, setFolderAccessLevels] = useState<Array<number>>(
    []
  );
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const sendUpFolderPath = (path: string) => {
    updateFolderPath(path);
  };

  const createFolder = async () => {
    setLoading(true);
    setFolderError('');
    setFolderSuccess('');
    const storedJWT = localStorage.getItem('jwt');
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('image', file); // Use "files[]" to handle multiple files
    });
    formData.append('access_level', folderAccessLevels.join(','));
    formData.append('folder_path', folderPath);
    formData.append('folder_name', folderName);

    if (folderName.trim() === '') {
      setFolderError('Folder name is required');
      setLoading(false);
    } else {
      await axios
        .post(process.env.REACT_APP_API_URL + '/create-folder.php', formData, {
          headers: {
            Authorization: 'Bearer ' + storedJWT,
          },
        })
        .then((response: any) => {
          const jsonData = response.data;

          if (jsonData.message) {
            setFolderSuccess(jsonData.message);
            if (jsonData.message == 'Folder created successfully.') {
              setFolderName('');
              setFolderError('');
              setLoading(false);
              setFolderAccessLevels([]);
              sendUpFolderPath(jsonData.folder_path);
            } else {
              setFolderError(jsonData.response.error);
            }
          } else if (jsonData.error) {
            setFolderError(jsonData.error);
          } else if (jsonData == 'Error decoding token: Expired token') {
            logout();
          }
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error?.response?.data?.error);
          setFolderError(error?.response?.data?.error);
          setLoading(false);
        });
    }
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedFiles(filesArray);
    }
  };
  const toggleAccessLevel = (level: number) => {
    if (folderAccessLevels.includes(level)) {
      //remove from array
      setFolderAccessLevels(
        folderAccessLevels.filter((item) => item !== level)
      );
    } else {
      //add to array
      setFolderAccessLevels([...folderAccessLevels, level]);
    }
  };

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  const getRoles = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-roles.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRoles(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div className="inline-block pr-6 mt-5 bg-white md:rounded-lg md:shadow-md md:p-6">
      <h1 className="mb-4 text-xl font-bold text-gray-800">Create folder</h1>
      <input
        className="block w-full px-2 py-1 text-gray-700 border border-gray-300 rounded-md"
        type="text"
        name="folder-name"
        placeholder="Folder name"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
      />

      <div className="mt-4 mb-6">
        <label
          htmlFor="access-level"
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Access Level:
        </label>
        <div className="relative flex flex-wrap inline-block w-auto">
          {roles.map((level: any) => (
            <a
              onClick={() => toggleAccessLevel(level.access_level)}
              key={level.access_level}
              className={`${
                folderAccessLevels.includes(level.access_level)
                  ? 'bg-green-600 text-white'
                  : 'bg-red-100 hover:bg-green-100 text-default'
              } mb-2 mr-3 cursor-pointer group py-1 px-3 rounded-md text-sm`}
            >
              {level.label}
            </a>
          ))}
        </div>
      </div>
      <div className="mt-4 mb-6">
        <label
          htmlFor="image"
          className="block mb-3 text-sm font-medium text-gray-700"
        >
          Image:
        </label>
        <input
          className="block w-full text-sm text-gray-700 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-[#e9e9e5] file:text-default hover:file:bg-indigo-100"
          type="file"
          name="image"
          id="image"
          onChange={handleFileChange}
        />
      </div>
      {loading ? (
        <svg
          className="inline-block w-8 h-8 text-accent animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <button
          className="inline-flex justify-center w-auto px-4 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-[#d3d3cd] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#d3d3cd]"
          onClick={createFolder}
        >
          Create
        </button>
      )}

      <p className="mt-2 text-sm font-medium text-green-600">{folderSuccess}</p>
      <p className="mt-2 text-sm font-medium text-red-600">{folderError}</p>
    </div>
  );
};

export default CreateFolder;
