import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CreateProduct from '../components/createproduct';
import Cart from '../components/cart';
import ProductBrowser from '../components/productbrowser';

//files interface
interface filesProps {}
const Products: FC<filesProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [counter, setCounter] = useState(0);

  const context = useContext(AppContext);
  const navigate = useNavigate();
  const updatePage = () => {
    setCounter((counter) => counter + 1);
  };
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(
        'https://api.gommaire-client-zone.yournewwebsite.be/check-status.php',
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Authorization: 'Bearer ' + storedJWT,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);
  const roles = JSON.parse(user.roles);
  return (
    <div className="pt-5 pl-5 fade-in md:pt-10 md:p-5 lg:pl-10 xl:pl-16">
      <ProductBrowser updatePage={updatePage} refreshCounter={counter} />
      <div className="grid max-w-3xl gap-5 mb-10 md:gap-10 lg:grid-cols-2 md:mb-0">
        {(roles.includes(0) || roles.includes(1)) && (
          <CreateProduct updatePage={updatePage} />
        )}
        <Cart />
      </div>
    </div>
  );
};
export default Products;
