import { useEffect, useState, FC, useRef, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import SearchResult from './searchresult';
interface SearchfilesProps {
  path: string;
  updateFolderPath: (path: string) => void;
}

const SearchFiles: FC<SearchfilesProps> = ({ path, updateFolderPath }) => {
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);

  const [results, setResults] = useState([]);
  const [showX, setShowX] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const searchFiles = async (path: string, text: string) => {
    const storedJWT = localStorage.getItem('jwt');
    setSearching(true);
    //Use the path to search for files
    if (text.trim() === '') {
      setSearching(false);
      return;
    }

    await fetch(
      'https://api.gommaire-client-zone.yournewwebsite.be/search-files.php',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          path,
          text,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data === 'Error decoding token: Expired token') {
          logout();
        }
        setResults(data);
        setShowX(true);
        if (data.length === 0) {
          setShowNoResults(true);
        }
      })
      .catch((error) => {
        // Handle network or server errors
        console.log('error');
        console.log(error);

        setSearching(false);
      });
    setSearching(false);
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  const resultsRef = useRef<HTMLDivElement>(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchFiles(path, searchText);
    }
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsRef.current &&
        !resultsRef.current.contains(event.target as Node)
      ) {
        setResults([]); // Step 2
        setSearchText('');
        setShowX(false);
        setShowNoResults(false);
      }
    }

    // Add click event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside); // Step 3
    };
  }, []);

  useEffect(() => {
    setShowX(false);
    setShowNoResults(false);
  }, [searchText]);

  return (
    <div className="relative">
      <div className="relative flex items-center justify-between w-full pr-2 border border-white rounded-md text-border">
        <input
          type="text"
          name="search"
          autoComplete="off"
          value={searchText}
          onKeyDown={handleKeyDown}
          placeholder="Search folder..."
          className="px-2 py-1 text-sm text-white placeholder-white bg-transparent rounded-md outline-none focus:bg-transparent"
          onChange={(e) => setSearchText(e.target.value)}
        />

        {showX ? (
          <svg
            onClick={() => {
              setSearchText('');
              setShowX(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-white cursor-pointer hover:text-gray-200"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        ) : !searching ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-white cursor-pointer hover:text-gray-200"
            onClick={() => searchFiles(path, searchText)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        ) : (
          <svg
            className="inline-block w-5 h-5 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="white"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {results.length > 0 && (
          <div
            ref={resultsRef}
            className="absolute shadow rounded-md left-0 top-10 max-h-[300px]  w-[250px] overflow-x-hidden overflow-y-scroll bg-white"
          >
            {results.map((result: any, index: number) => (
              <SearchResult
                updateFolderPath={updateFolderPath}
                key={index}
                result={result}
              />
            ))}
          </div>
        )}
      </div>
      {showNoResults && (
        <p className="absolute text-xs text-white -bottom-5 left-2">
          No results...
        </p>
      )}
    </div>
  );
};
export default SearchFiles;
