//create a react functional component in typescript
import { useEffect, useRef, useState, type FC } from 'react';

import BinItem from './binitem';

interface binbrowserProps {}

const BinBrowser: FC<binbrowserProps> = ({}) => {
  const viewList = ['list', 'small', 'medium', 'large'];
  const [files, setFiles] = useState<Array<any>>([]);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(viewList[0]);

  const getInitialFiles = async () => {
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    let thumb = view === 'list' ? 'small' : view;

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/get-bin-content.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
            size: thumb,
            page: 0,
          }),
        }
      );

      const data = await response.json();
      setFiles(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial files:', error);
      setLoading(false);
    }
  };
  const getFiles = async () => {
    setLoading(true);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');
    let thumb = view;
    if (view === 'list') {
      thumb = 'small';
    }
    user.email &&
      (await fetch(process.env.REACT_APP_API_URL + '/get-bin-content.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + storedJWT,
        },
        body: JSON.stringify({
          email: user.email,
          size: thumb,
          page: page,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          //add to existing array
          setFiles((files) => [...files, ...data]);
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          setLoading(false);
        }));
  };
  const getTotalFiles = async () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const storedJWT = localStorage.getItem('jwt');

    user.email &&
      (await fetch(
        process.env.REACT_APP_API_URL + '/get-total-bin-content.php',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + storedJWT,
          },
          body: JSON.stringify({
            email: user.email,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          //add to existing array
          setTotalFiles(data.total);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          setLoading(false);
        }));
  };
  //create the function to create a folder

  const toggleView = () => {
    setView(viewList[(viewList.indexOf(view) + 1) % viewList.length]);
  };

  useEffect(() => {
    getInitialFiles();
  }, [view]);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      await getTotalFiles();
    };

    fetchData();

    const div = scrollRef.current!;
    const handleScroll = () => {
      if (div) {
        const isAtBottom =
          div.scrollTop + div.clientHeight >= div.scrollHeight - 1;

        if (isAtBottom) {
          if (page * 20 < totalFiles) {
            setPage((page) => page + 1);
          }
        }
      }
    };

    if (div) {
      div.addEventListener('scroll', handleScroll);

      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollRef.current, page, totalFiles]);

  useEffect(() => {
    if (page !== 0 && page * 20 < totalFiles) {
      getFiles();
    }
  }, [page]);

  return (
    <div className="min-h-[300px] lg:py-10  ">
      <div className="relative flex min-h-[45px] items-center justify-between pr-7">
        <div className="flex gap-2 px-2">
          <button className="flex gap-1 group ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
            <span>/</span>
          </button>
          {loading && (
            <svg
              className="inline-block mt-0.5 w-5 h-5 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </div>
        {view === 'list' && (
          <button
            onClick={() => setView('large')}
            className={`min-h-[26px] content-center grid  md:hidden gap-0.5 group grid-cols-3`}
          >
            <div
              className={`rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd] w-2 h-1 col-span-1
            `}
            ></div>
            <div
              className={` rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd] w-4 h-1 col-span-2`}
            ></div>
            <div
              className={`w-2 h-1 col-span-1
                 rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]`}
            ></div>
            <div
              className={`w-4 h-1 col-span-2 rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]`}
            ></div>
          </button>
        )}
        {view === 'large' && (
          <button
            onClick={() => setView('list')}
            className={`min-h-[26px] grid md:hidden content-center gap-0.5 group grid-cols-1`}
          >
            <div
              className={`rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd] w-4 h-3
            `}
            ></div>
            <div
              className={`rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd] w-4 h-3
            `}
            ></div>
          </button>
        )}
        <button
          onClick={toggleView}
          className={`min-h-[26px] content-center  hidden md:grid gap-0.5 group 
           ${view === 'list' && 'grid-cols-3'} 
          ${view === 'small' && 'grid-cols-3'} 
          ${view === 'medium' && 'grid-cols-2'} 
          ${view === 'large' && 'grid-cols-1'}`}
        >
          <div
            className={`rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]
            ${view === 'list' && 'w-2 h-1 col-span-1'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            ${view === 'large' && 'w-4 h-3'} 
            `}
          ></div>
          <div
            className={` rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]
            ${view === 'list' && 'w-4 h-1 col-span-2'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            ${view === 'large' && 'w-4 h-3'} 
            `}
          ></div>
          {view !== 'large' && (
            <>
              <div
                className={` rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]
            ${view === 'list' && 'w-2 h-1 col-span-1'} 
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            `}
              ></div>
              <div
                className={` rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]
           ${view === 'list' && 'w-4 h-1 col-span-2'}           
            ${view === 'small' && 'w-2 h-2'} 
            ${view === 'medium' && 'w-3 h-3'} 
            `}
              ></div>
            </>
          )}

          {(view === 'small' || view === 'list') && (
            <>
              <div
                className={`
                ${view === 'list' ? 'w-2 h-1 col-span-1' : 'w-2 h-2'} 
              
                 rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]`}
              ></div>
              <div
                className={`
                ${view === 'list' ? 'w-4 h-1 col-span-2' : 'w-2 h-2'} 
               rounded-xs bg-slate-500 group-hover:bg-[#d3d3cd]`}
              ></div>
            </>
          )}
        </button>
      </div>

      <div
        ref={scrollRef}
        className={`${
          view === 'list' ? 'max-h-[60vh]' : 'max-h-screen'
        }  overflow-y-scroll border-b border-slate-100 pr-5`}
      >
        <div
          className={`${
            view === 'list' ? 'gap-1 my-5 ' : 'gap-4 mb-10 mt-5'
          } grid items-start grid-cols-4`}
        >
          {files &&
            files.length > 0 &&
            files.map((item: any) => (
              <BinItem key={item.ID} view={view} file={item} />
            ))}
        </div>
      </div>
    </div>
  );
};
export default BinBrowser;
