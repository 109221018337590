import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from '../utils/appContext';
import Icon from '../components/icons';
import ContactInfo from '../components/contactinfo';
import ChevRight from '../components/chevright';
const Navigation = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '/', icon: 'dashboard' },
  ]);
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    setActivePage(location.pathname.split('/')[1]);
  }, [location]);

  const roles = JSON.parse(user.roles || '[]');

  useEffect(() => {
    const newNavigation = [{ name: 'Dashboard', href: '/', icon: 'dashboard' }];

    if (roles.includes(0) || roles.includes(1)) {
      //push two items to the array

      newNavigation.push(
        {
          name: 'Members',
          href: '/members',
          icon: 'members',
        },
        { name: 'Contacts', href: '/contacts', icon: 'contact' }
      );
    }
    if (
      roles.includes(0) ||
      roles.includes(1) ||
      roles.includes(5) ||
      roles.includes(6) ||
      roles.includes(7)
    ) {
      //push two items to the array

      newNavigation.push({
        name: 'Products',
        href: '/products',
        icon: 'cart',
      });
    }
    if (roles.includes(0)) {
      newNavigation.push({ name: 'Bin', href: '/bin', icon: 'bin' });
    }

    setNavigation(newNavigation);
    setShow(true);
  }, []);

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  return (
    show && (
      <div className="grid content-between py-2 md:py-10 lg:min-h-screen">
        <div className="top">
          <div className="mb-5 text-center md:px-7 lg:mb-20 xl:mb-20">
            <img
              src="/favicon.png"
              alt="Gommaire"
              className="inline-block lg:hidden max-h-8"
            />
            <img
              src="/Gommaire-logo.png"
              alt="Gommaire"
              className="hidden lg:block max-h-32"
            />
          </div>

          <nav>
            <ul role="list" className="grid ">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    onClick={() => navigate(item.href)}
                    className={`${
                      activePage == item.name.toLowerCase()
                        ? 'bg-[#E9E9E5] '
                        : ' hover:bg-[#E9E9E5]'
                    }
                    cursor-pointer transition-all group flex justify-center lg:justify-between lg:px-10  py-3 px-3 md:px-5 text-sm leading-6 items-center`}
                  >
                    <div className="flex gap-x-3">
                      <Icon iconName={item.icon} />
                      <span className="hidden lg:block">{item.name}</span>
                    </div>

                    <span className="hidden lg:block mt-[3px]">
                      <ChevRight />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="mt-10 bottom">
          <div className="grid items-center ">
            <div className="hidden my-5 lg:block">
              <ContactInfo />
            </div>
            <a
              onClick={logout}
              className={`cursor-pointer text-gray-700 hover:text-indigo-600 hover:bg-gray-50
                      group  gap-x-3 px-3 md:px-5 lg:px-10  p-2 text-sm leading-6 flex justify-center lg:justify-start items-center`}
            >
              <Icon iconName={`logout`} />
              <span className="hidden lg:block">Logout</span>
            </a>
          </div>
        </div>
      </div>
    )
  );
};
export default Navigation;
